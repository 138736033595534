import React, { useEffect, useState } from "react";
import {
  hiddenFloatingIconLinks,
  hideMenuItemsLinks,
  IMAGE_VERSION,
  IMG_BASE,
  isMobile,
} from "../../common/config";
// import Anchor from "../anchor";
import TalkToUs from "../talkToUs";
import Cart from "../cart";
import UserProfile from "../userProfile";
// import GenericSearch from "../genericSearch";
import Navbar from "../navbar";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Announcement from "../announcement";
import { CtaButton } from "../ctaButton";
import { useLocation } from "react-router";
import EnrollOverlay from "../enrollOverlay";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { getPath, getStudentID, isLoggedIn } from "../../utils";
import { useGetCartQuery } from "../../api/services/cart";
import { cart } from "../../redux/actions";
import EnrolFloating from "../enrolFloating";
import BrochureOverlay from "../../pages/our-courses/brochureOverlay";
import CampaignBrochureOverlay from "../../pages/campaign/campaignBrochureOverlay";

const Header = () => {
  const location = useLocation();
  useEffect(() => {
    function initHeader() {
      gsap.registerPlugin(ScrollTrigger);
      const showAnim = gsap
        .from(".header__wrap", {
          yPercent: -100,
          paused: true,
          duration: 0.2,
        })
        .progress(1);

      ScrollTrigger.create({
        start: "top top",
        end: 99999,
        onUpdate: (self) => {
          self.direction === -1 ? showAnim.play() : showAnim.reverse();
        },
      });
    }

    initHeader();
  }, []);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  useEffect(() => {
    const BODY_CLASS = "noBodyScroll";
    const body = document.querySelector("body");
    if (isMenuOpen) {
      body?.classList.add(BODY_CLASS);
    } else {
      body?.classList.remove(BODY_CLASS);
    }
  }, [isMenuOpen]);

  useEffect(() => {
    if (location) {
      setIsMenuOpen(false);
    }
  }, [location]);

  const dispatch = useDispatch();
  const STUDENT_ID = getStudentID();
  const { data: cartData } = useGetCartQuery(STUDENT_ID, {
    refetchOnMountOrArgChange: true,
    skip: !isLoggedIn(),
  });
  useEffect(() => {
    if (cartData?.length) {
      dispatch(cart.getCartDetails(cartData));
    }
  }, [dispatch, cartData]);

  const { cartItems = [] } = useSelector((state) => state?.app?.cart);
  const homeState = useSelector((state) => state?.app?.home);

  const hideAnnouncementAndEnroll = !!hiddenFloatingIconLinks.find((link) =>
    location.pathname.includes(link)
  );
  const hideMenuItems = !!hideMenuItemsLinks.find((link) =>
    location.pathname.includes(link)
  );

  return (
    <>
      {homeState?.showEnrollOverlay && (
        <EnrollOverlay selectedClass={homeState?.selectedCourse} />
      )}
      {homeState?.showBrochureOverlay && <BrochureOverlay />}
      {homeState?.showCampaignBrochureOverlay && <CampaignBrochureOverlay />}
      {!hideAnnouncementAndEnroll && (
        <>
          <Announcement />
          <EnrolFloating />
        </>
      )}
      <header className="header__wrap">
        <div className="main__container--fluid">
          <div className="header__container">
            <NavLink className={"brand__logo"} to={getPath("home")}>
              <img
                draggable="false"
                src={`${IMG_BASE}ednovate-logo.svg?v=${IMAGE_VERSION}`}
                alt={"ednovate"}
              />
            </NavLink>
            {/* <Anchor
              linkCls={"brand__logo"}
              linkTarget={"_self"}
              hyperLink={"/"}
              component={
                <img
                  src={`${IMG_BASE}ednovate-logo.svg?v=${IMAGE_VERSION}`}
                  alt={"ednovate"}
                />
              }
            /> */}
            {!isMobile() && !hideMenuItems && (
              <div className="header__menu">
                <Navbar />
              </div>
            )}
            {!hideMenuItems && (
              <div className="header__widgets">
                {/* <GenericSearch /> */}
                <Cart badgeCount={cartItems?.length} />
                <UserProfile />
              </div>
            )}
            <TalkToUs />
            {isMobile() && !hideMenuItems && (
              <div className="header__hamburger">
                <div className="header__hamburger-head">
                  <CtaButton
                    btnCls={"header__hamburger-icon"}
                    iconCls={isMenuOpen ? "edi-close" : "edi-hamburger"}
                    onClickProp={() => setIsMenuOpen((value) => !value)}
                  />
                </div>
                {isMenuOpen && (
                  <div className="header__hamburger-body">
                    <Navbar />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
