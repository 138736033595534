import React from "react";
import Media from "../../media";
import Socials from "../../socials";
import { IMAGE, socialsList } from "../../../common/config";
import Anchor from "../../anchor";
import { NavLink } from "react-router-dom";
import { getMenuList, getPath, toKebabCase } from "../../../utils";
import { useSelector } from "react-redux";

const Sitemap = () => {
  const masterData = useSelector(
    (state) => state?.masterApi?.queries?.["getMasterData(undefined)"]?.data
  );

  const newCourseData = useSelector(
    (state) => state?.coursesApi?.queries?.["getNewCourses(undefined)"]?.data
  );

  const sitemapArr = [
    {
      title: "Site Link",
      childLinks: getMenuList(masterData?.courses)
        ?.filter((x) => !x?.childItem?.length)
        ?.map((data) => ({
          ...data,
          path: getPath(data?.path),
        })),
    },
    {
      title: "Buy Courses",
      childLinks: masterData?.batch?.map((data) => ({
        ...data,
        parentLbl: data?.batch,
        path: getPath("courses", {
          batch: toKebabCase(data.batch),
          course: toKebabCase(
            masterData?.courses?.find((x) => x.batch_id === data.id)?.title
          ),
        }),
      })),
    },
    {
      title: "Our Courses",
      childLinks: newCourseData?.map((data) => ({
        ...data,
        parentLbl: data?.title,
        path: getPath("ourCourses", {
          batch: toKebabCase(data?.title),
        }),
      })),
    },
    // {
    //   title: "Get in Touch",
    //   childLinks: [
    //     // { parentLbl: "Time Table", path: "/" },
    //     { parentLbl: "Careers", path: getPath("career") },
    //     { parentLbl: "Business", path: getPath("workWithUs") },
    //   ],
    // },
    {
      title: "Contact with Us",
      customMarkup: [
        {
          iconName: "edi-pinned",
          linkURL: "https://goo.gl/maps/sWkSXaXMLEdUkxWc6",
          content: `<b>Head Office:</b> 4th Floor, Ajanta Square Mall, Near BMC Market, L.T Road, Borivali (W), Mumbai: 400092.`,
        },
        {
          iconName: "edi-phone",
          linkURL: "tel:+917277367367",
          content: `(+91) 72773 67367`,
        },
        {
          iconName: "edi-mail",
          linkURL: `mailto: info@letsednovate.com`,
          content: ` info@letsednovate.com`,
        },
      ],
    },
  ];

  return (
    <>
      <div className="sitemap__wrap">
        <div className="main__container">
          <div className="sitemap__list">
            <div className="sitemap__grid">
              <div
                className="sitemap__grid-item"
                data-aos="fade-right"
                data-aos-delay="100"
              >
                <div className="sitemap__brandLogo">
                  <Media type={IMAGE} mediaURL={"ednovate-footer.svg"} />
                </div>
                <div className="sitemap__brandCaption">
                  <p>
                    Ednovate is an innovative platform uniquely dedicated to
                    addressing challenges within the commerce and professional
                    coaching industry. We provide a comprehensive, one-stop
                    solution tailored for students, guiding them through every
                    step of their professional journey with personalized support
                    and expertise.
                  </p>
                </div>
                <div className="sitemap__socials">
                  <Socials
                    arrProps={socialsList.map((x) => ({
                      iconCls: x.iconName,
                      hyperLink: x.url,
                      linkCls: `btn btn__icon btn__round ${
                        x.iconExtraCls ? x.iconExtraCls : ""
                      }`,
                    }))}
                    isAnchor={true}
                  />
                </div>
              </div>
              {sitemapArr.map((item, index) => (
                <div
                  key={index}
                  className="sitemap__grid-item"
                  data-aos="fade-right"
                  data-aos-delay={120 * (index + 1)}
                >
                  <h4 className="sitemap__heading">{item.title}</h4>
                  {item.childLinks && (
                    <div className="sitemap__links">
                      {item.childLinks?.map((childItem, childIndex) => (
                        <NavLink to={childItem.path} key={childIndex}>
                          <span>{childItem.parentLbl}</span>
                        </NavLink>
                      ))}
                    </div>
                  )}
                  {item.customMarkup?.map((item2, index) => (
                    <Anchor
                      key={index}
                      hyperLink={item2.linkURL}
                      component={
                        <>
                          <div className="sitemap__contactDtls">
                            <div className="sitemap__contactDtls-icon btn__icon">
                              <i className={item2.iconName}></i>
                            </div>
                            <div
                              className="sitemap__contactDtls-content"
                              dangerouslySetInnerHTML={{
                                __html: item2.content,
                              }}
                            ></div>
                          </div>
                        </>
                      }
                    />
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sitemap;
