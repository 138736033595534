import React, { useEffect, useState } from "react";
import Dropdown from "../dropdown";
import { useNavigate } from "react-router";
import { getMenuList, getPath, toKebabCase } from "../../utils";
import { NavLink } from "react-router-dom";
import { isMobile } from "../../common/config";
import { useSelector } from "react-redux";
const Navbar = () => {
  const navigate = useNavigate();

  const masterData = useSelector(
    (state) => state?.masterApi?.queries?.["getMasterData(undefined)"]?.data
  );
  const newCourseData = useSelector(
    (state) => state?.coursesApi?.queries?.["getNewCourses(undefined)"]?.data
  );

  function handleScrollToSection(path, parent, type) {
    if (type === "section") {
      const element = document.querySelector(`#${toKebabCase(path.title)}`);
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: "smooth" });
        }, 300);
      }
    }
  }

  function getNavPath(path, parent, type) {
    if (type === "our-course") {
      return `${getPath("ourCourses", {
        batch: toKebabCase(path.title),
      })}`;
    }
    if (type === "course") {
      return `${getPath(parent, {
        batch: toKebabCase(path.title),
        course: toKebabCase(
          masterData?.courses?.find((x) => x.batch_id === path.id)?.title
        ),
      })}`;
    }
    if (type === "section") {
      return `${getPath(parent)}#${toKebabCase(path.title)}`;
    }
  }

  function handleChildNavigate(path, parent, type) {
    navigate(getNavPath(path, parent, type));
    handleScrollToSection(path, parent, type);
  }

  function handleMoreNavigate({ path }) {
    navigate(getPath(path));
  }

  const [menuList, setMenuList] = useState(
    getMenuList(
      masterData?.batch?.map((x) => ({
        ...x,
        title: x.batch,
        type: "course",
        parent: "courses",
      })),
      newCourseData?.map((x) => ({
        ...x,
        type: "our-course",
        parent: "our-course",
      }))
    )
  );
  const [moreMenu, setMoreMenu] = useState({
    parentLbl: "More",
    parentRoute: "",
    childItem: [],
  });

  const headerWrap = document.querySelector(".header__wrap")?.clientWidth;
  const headerMenu = document.querySelector(".header__menu")?.clientWidth;

  useEffect(() => {
    function checkMenuItems() {
      if (!isMobile() && headerMenu / headerWrap > 0.6 && menuList?.length) {
        const menu = menuList;
        const lastMenuItem = menu.pop();
        setMenuList(menu);
        setMoreMenu((state) => ({
          ...state,
          childItem: [
            ...state?.childItem,
            {
              ...lastMenuItem,
              title: lastMenuItem?.parentLbl,
            },
          ],
        }));
      }
    }
    checkMenuItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuList]);

  return (
    <nav className="navbar">
      <ul>
        {!isMobile() ? (
          <>
            {menuList?.map((item, index) => (
              <li key={index}>
                {item.childItem ? (
                  <Dropdown
                    list={item.childItem}
                    value={{ title: item.parentLbl }}
                    handleClick={(path) =>
                      handleChildNavigate(
                        path,
                        path?.parent || item.parentRoute,
                        path.type
                      )
                    }
                    toggleOnHover
                  />
                ) : (
                  <NavLink to={getPath(item.path)}>
                    <span>{item.parentLbl}</span>
                  </NavLink>
                )}
              </li>
            ))}
            {moreMenu?.childItem?.length && (
              <li>
                <Dropdown
                  list={moreMenu?.childItem}
                  value={{ title: moreMenu.parentLbl }}
                  handleClick={(path) => handleMoreNavigate(path)}
                  toggleOnHover
                />
              </li>
            )}
          </>
        ) : (
          menuList?.map((item, index) => (
            <li key={index}>
              <NavLink to={getPath(item.path)}>
                <span>{item.parentLbl}</span>
                {item.childItem && (
                  <ul>
                    {item.childItem.map((child, index) => (
                      <li
                        key={index}
                        onClick={() =>
                          handleScrollToSection(
                            child,
                            item.parentRoute,
                            child.type
                          )
                        }
                      >
                        <NavLink
                          to={getNavPath(
                            child,
                            child?.parent || item.parentRoute,
                            child.type
                          )}
                        >
                          <span>{child.title}</span>
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                )}
              </NavLink>
            </li>
          ))
        )}
      </ul>
    </nav>
  );
};

export default Navbar;
