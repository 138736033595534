import React, { Suspense, lazy, useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Loader from "../components/loader";
import routesPath from "./routes";

const AppLayout = lazy(() =>
  import(/* webpackChunkName: 'appLayout' */ "../components/appLayout/index")
);

const Home = lazy(() => import(/* webpackChunkName: 'home' */ "../pages/home"));
const Course = lazy(() =>
  import(/* webpackChunkName: 'home' */ "../pages/course")
);
const CourseDetails = lazy(() =>
  import(/* webpackChunkName: 'home' */ "../pages/course/detail.js")
);
const About = lazy(() =>
  import(/* webpackChunkName: 'about' */ "../pages/about")
);
const Contact = lazy(() =>
  import(/* webpackChunkName: 'contact' */ "../pages/contact")
);
const Ednoverse = lazy(() =>
  import(/* webpackChunkName: 'ednoverse' */ "../pages/ednoverse")
);
// const MahaRevision = lazy(() =>
//   import(/* webpackChunkName: 'maha-revision' */ "../pages/maha-revision")
// );
const TopRankers = lazy(() =>
  import(/* webpackChunkName: 'top-rankers' */ "../pages/top-rankers")
);
const FreeResources = lazy(() =>
  import(/* webpackChunkName: 'free-resources' */ "../pages/free-resources")
);
const Career = lazy(() =>
  import(/* webpackChunkName: 'career' */ "../pages/career")
);
const WorkWithUs = lazy(() =>
  import(/* webpackChunkName: 'work-with-us' */ "../pages/work-with-us")
);
const PrivacyPolicy = lazy(() =>
  import(/* webpackChunkName: 'privacy-policy' */ "../pages/privacy-policy")
);
const RefundPolicy = lazy(() =>
  import(/* webpackChunkName: 'refund-policy' */ "../pages/refund-policy")
);
const TermsConditions = lazy(() =>
  import(
    /* webpackChunkName: 'terms-and-conditions' */ "../pages/terms-and-conditions"
  )
);
const Cart = lazy(() => import(/* webpackChunkName: 'cart' */ "../pages/cart"));
const Login = lazy(() =>
  import(/* webpackChunkName: 'login' */ "../pages/login")
);
const Register = lazy(() =>
  import(/* webpackChunkName: 'login' */ "../pages/register")
);
const DashboardLayout = lazy(() =>
  import(/* webpackChunkName: 'dashboard' */ "../layouts/dashboard")
);
const Profile = lazy(() =>
  import(/* webpackChunkName: 'dashboard' */ "../pages/dashboard/profile.js")
);
const OrderHistory = lazy(() =>
  import(
    /* webpackChunkName: 'dashboard' */ "../pages/dashboard/orderHistory.js"
  )
);
const Schedule = lazy(() =>
  import(/* webpackChunkName: 'dashboard' */ "../pages/dashboard/schedule.js")
);
const PaymentSuccess = lazy(() =>
  import(/* webpackChunkName: 'payment' */ "../pages/payment-success/index.js")
);
const PaymentFailure = lazy(() =>
  import(/* webpackChunkName: 'payment' */ "../pages/payment-failure/index.js")
);
const Enroll = lazy(() =>
  import(/* webpackChunkName: 'payment' */ "../pages/enroll/index.js")
);

const OurCourses = lazy(() =>
  import(/* webpackChunkName: 'payment' */ "../pages/our-courses/index.js")
);

const Campaign = lazy(() =>
  import(/* webpackChunkName: 'payment' */ "../pages/campaign/index.js")
);

const Thankyou = lazy(() =>
  import(/* webpackChunkName: 'home' */ "../pages/thank-you/index.js")
);

const router = createBrowserRouter(
  [
    {
      path: "",
      element: <AppLayout />,
      children: [
        {
          id: "home",
          path: routesPath?.home.path,
          element: <Home />,
        },
        {
          id: "buy_course",
          path: routesPath?.courses.path,
          element: <Course />,
          children: [],
        },
        {
          id: "courseDetail",
          path: routesPath.coursesDetail.path,
          element: <CourseDetails />,
        },
        {
          id: "about",
          path: routesPath?.about.path,
          element: <About />,
        },
        {
          id: "contact",
          path: routesPath?.contact.path,
          element: <Contact />,
        },
        {
          id: "ednoverse",
          path: routesPath?.ednoverse.path,
          element: <Ednoverse />,
        },
        // {
        //   id: "maha_revision",
        //   path: routesPath?.mahaRevision.path,
        //   element: <MahaRevision />,
        // },
        {
          id: "topRankers",
          path: routesPath?.topRankers.path,
          element: <TopRankers />,
        },
        {
          id: "free_resources",
          path: routesPath?.freeResources.path,
          element: <FreeResources />,
        },
        {
          id: "cart",
          path: routesPath?.cart.path,
          element: <Cart />,
        },
        {
          id: "login",
          path: routesPath?.login.path,
          element: <Login />,
        },
        {
          id: "career",
          path: routesPath?.career.path,
          element: <Career />,
        },
        {
          id: "workWithUs",
          path: routesPath?.workWithUs.path,
          element: <WorkWithUs />,
        },
        {
          id: "privacyPolicy",
          path: routesPath?.privacy.path,
          element: <PrivacyPolicy />,
        },
        {
          id: "refundPolicy",
          path: routesPath?.refund.path,
          element: <RefundPolicy />,
        },
        {
          id: "termsConditions",
          path: routesPath?.terms.path,
          element: <TermsConditions />,
        },
        {
          id: "register",
          path: routesPath?.register.path,
          element: <Register />,
        },
        {
          id: "payment-success",
          path: routesPath?.paymentSuccess.path,
          element: <PaymentSuccess />,
        },
        {
          id: "payment-failure",
          path: routesPath?.paymentFailure.path,
          element: <PaymentFailure />,
        },
        {
          id: "enroll",
          path: routesPath?.enroll.path,
          element: <Enroll />,
        },
        {
          id: "our-courses",
          path: routesPath?.ourCourses.path,
          element: <OurCourses />,
        },
        {
          id: "campaign",
          path: routesPath?.campaign.path,
          element: <Campaign />,
        },
        {
          id: "thank-you",
          path: routesPath?.thankyou.path,
          element: <Thankyou />,
        },
      ],
    },
    {
      path: routesPath.dashboard.path,
      element: <DashboardLayout />,
      children: [
        {
          path: routesPath.profile.path,
          element: <Profile />,
        },
        {
          path: routesPath.orderHistory.path,
          element: <OrderHistory />,
        },
        {
          path: routesPath.schedule.path,
          element: <Schedule />,
        },
      ],
    },
  ],
  {
    basename: process.env.PUBLIC_URL,
  }
);

function RouteConfig() {
  useEffect(() => {
    //scroll Top
    // window.scroll({
    //   top: 100,
    //   left: 100,
    //   behavior: "smooth",
    // });
  }, []);

  return (
    <Suspense
      fallback={
        <div className="master__wrap">
          <Loader />
        </div>
      }
    >
      <RouterProvider router={router} />
    </Suspense>
  );
}

export default RouteConfig;
